

<div class="table-settings-container">
  <div class="table-settings-row-size-container">
    <div class="ts-table-row-size-title">{{'general.labels.ViewMode' | translate}}</div>
    <div class="ts-table-row-size-buttons-container">
      <button class="c4p-button ts-table-row-size-button" [ngClass]="{'active': tableRowSize === 'default'}"
      (click)="onTableRowSizeChange('default')">{{'general.labels.ExtendMode' | translate}}</button>
      
      <button class="c4p-button ts-table-row-size-button" [ngClass]="{'active': tableRowSize === 'compact'}"
      (click)="onTableRowSizeChange('compact')">{{'general.labels.CompactMode' | translate}}</button>
    </div>
  </div>
  
  <div class="table-settings-column-list" cdkDropList (cdkDropListDropped)="drop($event)">
    @for (column of columns; track column) {
        <div cdkDrag class="table-settings-list-item">
          <div class="ts-list-item-sub-container">
            <img src="/assets/svg/dots-grid.svg" alt="">
            <span>{{ column.name | translate }}</span>
          </div>
          
          <div class="ts-list-item-sub-container">
            <img (click)="onPinColumn(column)" [class.disabled]="column.hidden" src="{{ column.frozenLeft ? '/assets/svg/pin.svg' : '/assets/svg/unpin.svg' }}" alt="" />
            <mat-slide-toggle [checked]="!column.hidden" (change)="onToggleColumn(column)"></mat-slide-toggle>
          </div>
          
          <div class="ts-list-item-drag-placeholder" *cdkDragPlaceholder></div>

        </div>
    }
  </div>
</div>