<div class="tuula-table-container">
  <div class="tuula-table-top-bar">
    <div class="tuula-table-title">
      {{ title | translate }}
      <div class="tuula-table-title-template" *ngIf="customTitleElement">
        <ng-container *ngTemplateOutlet="customTitleElement">
        </ng-container>
      </div>
    </div>
    <div class="tuula-table-controls">
      <ng-container *ngTemplateOutlet="tableControls">
      </ng-container>
      <button *ngIf="tableFilterVisible" (click)="onToggleFilter()" class="tuula-table-control-button">
        <mat-icon>filter_list</mat-icon>
      </button>
      <button *ngIf="tableSettingsVisible" (click)="onOpenTableSettings()" class="tuula-table-control-button">
        <mat-icon>settings</mat-icon>
      </button>
    </div>
  </div>
  <div cdkDropList
       cdkDropListOrientation="horizontal"
       (cdkDropListDropped)="drop($event)">
    <mat-table [dataSource]="dataSource" class="tuula-table"
               matSort (matSortChange)="onSortChanged($event)">
      @for (column of columns; track column) {
        <ng-container [matColumnDef]="column.prop" [sticky]="column.frozenLeft" [stickyEnd]="column.frozenRight">
          <mat-header-cell cdkDrag cdkDragLockAxis="x" [cdkDragDisabled]="column.draggable === false" *matHeaderCellDef
                           mat-sort-header [disabled]="column.sortable === false"
                           [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : 180, 'justify-content': column.justify ? column.justify :'start' }"
          >
            @if (column.headerTemplate) {
              <ng-container
                *ngTemplateOutlet="column.headerTemplate; context: { column: column }">
              </ng-container>
            } @else {
              <span>{{ column.name | translate }}</span>
            }
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index"
                    [ngClass]="[tableRowSize === 'default'? 'tuula-table-row-default' : 'tuula-table-row-compact']"
                    [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : 180, 'justify-content': column.justify ? column.justify :'start' }"
          >
            @if (column.cellTemplate) {
              <ng-container
                *ngTemplateOutlet="column.cellTemplate; context: { row: row, rowIndex: i, rowValue: getNestedValue(row, column) }">
              </ng-container>
            } @else {
              <span
                [matTooltip]="getNestedValue(row, column)">
                {{ getNestedValue(row, column) }}
            </span>
            }
          </mat-cell>
        </ng-container>

          <!-- Filter Columns -->
        <ng-container matColumnDef="{{column.prop + '-filter'}}" [sticky]="column.frozenLeft" [stickyEnd]="column.frozenRight">
          <mat-header-cell *matHeaderCellDef
          [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : 180, 'justify-content': column.justify ? column.justify :'start' }">
            @if (filterPanelOpened){
                <app-tuula-table-filter-header-column style="width:100%" [column]="column" (filterChanged)="onApplyFilter($event)"></app-tuula-table-filter-header-column>
            }
          </mat-header-cell>
        </ng-container>
      }
      <mat-header-row *matHeaderRowDef="tableDisplayedColumns"></mat-header-row>
      <mat-header-row class="{{filterPanelOpened ? '' : 'invisible-header-row'}}" *matHeaderRowDef="tableDisplayedFilterColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableDisplayedColumns;"></mat-row>

    </mat-table>
  </div>
  <mat-paginator [pageSizeOptions]="pageSizeOptions"
                 [showFirstLastButtons]="false"
                 [length]="pagination?.totalDocs"
                 (page)="onPageChanged($event)"
                 [pageSize]="pagination?.pageSize"
                 [pageIndex]="pagination?.page - 1"
  >
  </mat-paginator>
</div>

