@if (column.enableFiltering){
  @if (column.filterTemplate) {
    <ng-container style="width:100%" *ngTemplateOutlet="column.filterTemplate; context: { column: column }"></ng-container>
  } @else {

    <div style="width: 100%" *ngIf="column">
      @if (column?.filter?.type === 'text' || column?.filter?.type === 'number') {
        <mat-form-field appearance="outline" class="table-header-filter-form-field">
          <input matInput [formControl]="valueControl">
        </mat-form-field>
      } @else if (column?.filter?.type === 'date' || column?.filter?.type === 'dateTime') {
        <mat-form-field appearance="outline" class="table-header-filter-form-field">
          <input matInput [formControl]="valueControl" [matDatepicker]="datepicker" (click)="datepicker.open()">
          <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker></mat-datepicker>
        </mat-form-field>
      } @else if (column?.filter?.type === 'time') {
        <mat-form-field appearance="outline" class="table-header-filter-form-field">
          <input matInput [formControl]="valueControl"
                 [ngxTimepicker]="toggleTimepicker"
                 [disableClick]="true"
                 (keydown)="onKeydown($event)"
                 [format]="24">
          <ngx-material-timepicker-toggle
            [for]="toggleTimepicker"
          ></ngx-material-timepicker-toggle>
          <ngx-material-timepicker #toggleTimepicker></ngx-material-timepicker>
        </mat-form-field>
      }
      @else if (column?.filter?.type === 'select' || column?.filter?.type === 'boolean') {
        <mat-form-field appearance="outline" class="table-header-filter-form-field">

          <mat-select [formControl]="valueControl" [multiple]="column?.filter?.multiSelection" [panelWidth]="null">

            @if (column?.filter?.loadData) {
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="searchControl"
                  [clearSearchInput]="false"
                  [searching]="isSeachResultLoading"
                  placeholderLabel="{{ 'general.actions.Search' | translate }}..."
                  noEntriesFoundLabel="{{ 'general.labels.Empty' | translate }}">
                </ngx-mat-select-search>
              </mat-option>
            }

            @if (!column?.filter?.multiSelection) {
              <mat-option></mat-option>
            }

            @for (option of column?.filter?.options; track option) {
              <mat-option class="table-header-select-option" [value]="option[column.filter.optionValueProperty]">{{option[column.filter.optionLabelProperty] | translate}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>

  }
}

