import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize, startWith, Subject, takeUntil } from 'rxjs';
import { DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT, DEFAULT_TIME_MINUTE_STEP, TIME_REGEX } from '../../../form-controls';
import { TableColumn } from '../../../models';

@Component({
  selector: 'app-tuula-table-filter-header-column',
  templateUrl: './tuula-table-filter-header-column.html',
  styleUrl: './tuula-table-filter-header-column.scss'
})
export class TuulaTableFilterHeaderColumnComponent implements OnInit, OnDestroy {

  @Input() column!: TableColumn;
  @Output() filterChanged = new EventEmitter<{ column: TableColumn }>();

  private readonly destroyed$ = new Subject<boolean>();

  valueControl = new FormControl();
  searchControl: FormControl = new FormControl();
  isSeachResultLoading = false;

  constructor() { }


  ngOnInit(): void {
    if (this?.column?.enableFiltering) {

      if (this?.column?.filter?.type === 'select') {
        if (this?.column?.filter?.loadData) {
          this.subscribeSearchTextChanges();
        } else {
          this.setDefaultSelectParameters(this?.column?.filter?.options);
        }
      }

      this.valueControl.patchValue(this.column?.filter?.filterValue);
      this.subcribeValueControlChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  subcribeValueControlChanges() {
    this.valueControl.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((val) => {
      this.column.filter.filterValue = val;
      if (this?.column?.filter?.type === 'select') {
         this.updateSelectedOptions();
      }
      this.filterChanged.emit({ column: this.column })
    });
  }

  subscribeSearchTextChanges() {

    this.searchControl.valueChanges.pipe(
      startWith(this.searchControl.value),
      distinctUntilChanged(),
      debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
      takeUntil(this.destroyed$),
    ).subscribe((searchVal) => {
      this.searchText(searchVal);
    });
  }

  searchText(searchVal) {
    this.isSeachResultLoading = true;
    this.column.filter.loadData(this.column, searchVal).pipe(
      finalize(() => (this.isSeachResultLoading = false)),
    ).subscribe((result) => {
      this.setDefaultSelectParameters(result);
    });
  }

  setDefaultSelectParameters(defaultOptions) {
    this.column.filter.options = defaultOptions ?? [];
    this.column.filter.optionLabelProperty = this?.column?.filter?.optionLabelProperty ?? 'label';
    this.column.filter.optionValueProperty = this?.column?.filter?.optionValueProperty ?? 'value';
    if (this.column.filter.filterValue) {
      this.addMissingSelectedOptions();
    }
  }

  updateSelectedOptions() {
    if (this.column?.filter?.multiSelection) {
      this.column.filter.selectedOptionObjects = this.column.filter.filterValue.map(val => this.getSelectedObject(val)).filter(val => val != null);
    } else {
      this.column.filter.selectedOptionObject = this.getSelectedObject(this.column.filter.filterValue);
    }
  }

  getSelectedObject(value) {
    return this.column.filter.options.find(opt => opt[this.column.filter.optionValueProperty] === value) ?? null;
  }

  addMissingSelectedOptions() {

    if (this.column?.filter?.multiSelection) {
      this.column?.filter?.filterValue?.forEach(element => {
        const selected = this.column.filter.selectedOptionObjects?.find(opt => opt[this.column.filter.optionValueProperty] === element)
        this.addMissingSelectedOption(element, selected);
      });
    } else {
      this.addMissingSelectedOption(this.column.filter.filterValue, this.column.filter.selectedOptionObject);
    }
  }

  addMissingSelectedOption(value, option) {
    const found = this.column.filter.options.find(opt => opt[this.column.filter.optionValueProperty] === value);
    if (!found) {
      this.column.filter.options.unshift(option);
    }
  }

  protected readonly onKeydown = TIME_REGEX;
}
